import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import "./assets/font-awesome/5.15.3/css/all.css";
import "./assets/css/custom.css";
import 'floating-vue/dist/style.css';
import 'vue3-toastify/dist/index.css';
import ArgonDashboard from "./argon-dashboard";
import FloatingVue from 'floating-vue';
import VueMask from '@devindex/vue-mask';
import VueTheMask from 'vue-the-mask'
import './registerServiceWorker'

const appInstance = createApp(App);
appInstance.use(store);
appInstance.use(router);
appInstance.use(ArgonDashboard);
appInstance.use(FloatingVue);
appInstance.use(VueMask);
appInstance.use(VueTheMask);
appInstance.mount("#app");
