<script setup>
import { onBeforeUnmount, onBeforeMount } from "vue";
import { useStore } from "vuex";
import axios from "axios";
import cookie from '../assets/js/cookies.js';
import loadGif from "@/assets/img/loading.gif"
import router from "../router/index.js";


const body = document.getElementsByTagName("body")[0];

const store = useStore();

onBeforeMount(() => {

  let session = cookie.get('user_agive_session');

  if(session != null){
    router.push({name: 'Visão Geral'});
  }

  store.state.hideConfigButton = true;
  store.state.showNavbar = false;
  store.state.showSidenav = false;
  store.state.showFooter = false;
  body.classList.remove("bg-gray-100");

  store.state.email = {
    hasError: false,
    messageError: '',
    value: '',
  };

  store.state.password = {
    hasError: false,
    messageError: '',
    value: '',
  };

  store.state.remember = {
    checked: false,
  };

  store.state.login = {
    hasError: false,
    messageError: ''
  };

  store.state.isLoging = false;
});
onBeforeUnmount(() => {
  store.state.hideConfigButton = false;
  store.state.showNavbar = true;
  store.state.showSidenav = true;
  store.state.showFooter = true;
  body.classList.add("bg-gray-100");
});

const login = () => {

  store.state.isLoging = true;

  let data = new FormData();

  store.state.email.hasError = false;
  store.state.password.hasError = false;
  store.state.login.hasError = false;

  data.append('email', store.state.email.value);
  data.append('password', store.state.password.value);
  data.append('remember', store.state.remember.checked);

  axios.post('/auth', data)
  .then(res => {

    if(res.data.error){

      let messages = res.data.messages;

      Object.entries(messages).forEach((value) => {

        if(value[0] == 'email'){
          store.state.email.hasError = true;
          store.state.email.messageError = value[1];
        }

        else if(value[0] == 'password'){
          store.state.password.hasError = true;
          store.state.password.messageError = value[1];
        }

        else if(value[0] == 'error'){
          store.state.login.hasError = true;
          store.state.login.messageError = value[1];
        }

      })

      store.state.isLoging = false;

    }
    else{

      let expires = 86400;

      if(store.state.remember.checked){

        expires = 2592000;

      }

      store.state.email = {
        hasError: false,
        messageError: '',
        value: '',
      };

      store.state.password = {
        hasError: false,
        messageError: '',
        value: '',
      };

      store.state.remember = {
        checked: false,
      };

      cookie.add('user_agive_session', res.data, expires);

      window.location.href = '/inicio';

    }

  })
  .catch(err => {
    console.error(err); 
  })

}
</script>
<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        
      </div>
    </div>
  </div>
  <main class="mt-0 main-content">
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row">
            <div
              class="mx-auto col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0"
            >
              <div class="card card-plain">
                <div class="pb-0 card-header text-center text-lg-start">
                  <h4 class="font-weight-bolder">Acesse sua conta</h4>
                  <p class="mb-0">Digite seu e-mail e senha para entrar</p>
                </div>
                <div class="card-body">
                  <div class="mb-3">
                      <input 
                        type="email" 
                        class="form-control form-control-lg" 
                        id="email" 
                        v-model="store.state.email.value" 
                        placeholder="E-mail"
                        :class="store.state.email.hasError ? 'is-invalid' : ''"
                      >
                      <div id="emailFeedback" class="invalid-feedback">
                        {{ store.state.email.messageError }}
                      </div>
                    </div>
                    <div class="mb-3">
                      <input 
                        type="password" 
                        class="form-control form-control-lg" 
                        id="password" 
                        v-model="store.state.password.value" 
                        placeholder="Senha"
                        :class="store.state.password.hasError ? 'is-invalid' : ''"
                      >
                      <div id="passwordFeedback" class="invalid-feedback">
                        {{ store.state.password.messageError }}
                      </div>
                    </div>
                    <div class="mb-3">
                      <div class="form-check">
                          <input 
                            class="form-check-input" 
                            type="checkbox" 
                            id="termsCheck" 
                            v-model="store.state.remember.checked"
                          >
                          <label class="form-check-label" for="termsCheck">
                            Lembrar-me
                          </label>
                          
                      </div>
                      
                    </div>
                    
                    <div class="text-center">
                      <button
                        v-if="!store.state.isLoging"
                        class="my-4 mb-2 btn-lg btn bg-gradient-success w-100"
                        @click="login()"
                      >
                        Entrar
                      </button>
                      <img v-if="store.state.isLoging" :src="loadGif" alt="" width="50">
                    </div>
                    <div v-if="store.state.login.hasError" class="col-12 mt-3">
                      <div class="text-danger text-center">
                        {{ store.state.login.messageError }}
                      </div>
                    </div>
                    <div class="col-12 mt-3 mb-3 text-center text-lg-start">
                      <hr>
                      Ainda não possui conta? <a href="/cadastro"><br class="d-lg-block">Faça o cadastro!</a>
                    </div>
                </div>
                <div class="px-1 pt-0 text-center card-footer px-lg-2">
                  
                </div>
              </div>
            </div>
            <div
              class="top-0 my-auto text-center col-6 d-lg-flex d-none h-100 pe-0 position-absolute end-0 justify-content-center flex-column"
            >
              <div
                class="position-relative bg-gradient-primary h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center overflow-hidden"
                style="
                  background-image: url(&quot;https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/signin-ill.jpg&quot;);
                  background-size: cover;
                "
              >
                <span class="mask bg-gradient-success opacity-6"></span>
                <h4
                  class="mt-5 text-white font-weight-bolder position-relative"
                >
                  AGIVE FINANÇAS
                </h4>
                <p class="text-white position-relative">
                  The more effortless the writing looks, the more effort the
                  writer actually put into the process.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
