import axios from "axios";
import router from "../../router";
import cookies from "./cookies";

function isLogged() {

    let session = cookies.get('user_agive_session');

    if(session == null){
        router.push({name: 'Login'});
    }
    else{
        
        axios.get('/helpers/is-logged?session='+session)
        .then(res => {
            
            if(!res.data.isValid){
                cookies.remove('user_agive_session');
                router.push({name: 'Login'});
            }

        })
        .catch(err => {
            console.error(err); 
        })

    }

}

export default {
    isLogged: isLogged
}