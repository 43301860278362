<script setup>
defineProps({
    bank: {
        type: String,
        required: true
    },
    id: {
        type: String,
        default: "",
    },
    icon: {
        type: String,
        default: "",
    },
    edit: {
        type: Object
    }
    
});

const emit = defineEmits(['edit', 'archive', 'delete']);

const editAccount = (data) => {
    emit('edit', data);
}

const archiveAccount = (category, archive) => {

    emit('archive', category, archive);
}

const deleteAccount = (category) => {

    emit('delete', category);
}

</script>

<template>
    <div class="col-11 mx-auto">

        <div class="row align-items-center row-accounts-list">

            <div class="col-2 col-lg-1 ms-3">
                <img :src="icon" alt="Banco Logo" width="50" class="rounded-circle">
            </div>
            <div class="col-4">
                <h6 class="ms-2">{{ bank }}</h6>
            </div>
            <div class="col-5 col-lg-6 text-end">
                <a v-if="edit.archived == 'No'" @click="editAccount(edit)" data-bs-toggle="modal" data-bs-target="#addAccount" class="me-3">
                    <i 
                        class="fal fa-pencil text-black-50 row-accounts-list-icons-actions"
                        v-tooltip="{
                                    content:'Editar',
                                    distance: 15
                                }"
                        
                    >
                    </i>
                </a>
                <a v-if="edit.archived == 'No'" @click="archiveAccount(edit, 'Yes')" class="me-3">
                    <i 
                        class="fal fa-inbox-in text-black-50 row-accounts-list-icons-actions"
                        v-tooltip="{
                                    content:'Arquivar',
                                    distance: 15
                                }"
                    >
                    </i>
                </a>
                <a v-if="edit.archived == 'Yes'" @click="archiveAccount(edit, 'No')"  class="me-3">
                    <i 
                        class="fal fa-inbox-out text-black-50 row-accounts-list-icons-actions"
                        v-tooltip="{
                                    content:'Desarquivar',
                                    distance: 15
                                }"
                    >
                    </i>
                </a>
                <a v-if="edit.archived == 'Yes'" @click="deleteAccount(edit)" class="me-3">
                    <i 
                        class="fal fa-trash text-danger row-accounts-list-icons-actions"
                        v-tooltip="{
                                    content:'Excluir',
                                    distance: 15
                                }"
                    >
                    </i>
                </a>
            </div>

        </div>

    </div>
</template>

<style>
    .row-accounts-list{
        height: 70px;
    }

    .row-accounts-list{
        border-bottom: solid 1px #dee2e6;
    }

    .row-accounts-list:hover{
        background-color: #f6f9fc;
        cursor: pointer;
    }

    .row-accounts-list-icons{
        font-size: 25px;
    }

    .row-accounts-list-icons-actions{
        font-size: 20px;
    }
</style>