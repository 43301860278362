<script setup>
import { onMounted } from 'vue';
import setTooltip from '../../assets/js/tooltip';

defineProps({
    name: {
        type: String,
        required: true
    },
    id: {
        type: String,
        default: "",
    },
    icon: {
        type: String,
        default: "",
    },
    color_icon: {
        type: String,
        default: "",
    },
    subs: {
        type: Object
    },
    edit: {
        type: Object
    }
    
});

onMounted(() => {
  setTooltip
});

const emit = defineEmits(['edit', 'archive', 'delete']);

const editCategory = (data) => {
    emit('edit', data);
}

const archiveCategory = (category, archive) => {

    emit('archive', category, archive);
}

const deleteCategory = (category) => {

    emit('delete', category);
}

</script>

<template>
    <div class="col-12">

        <div class="row align-items-center row-categories">

            <div class="col-2 col-lg-1 ms-0 ms-lg-3 text-center">
                <i :class="'fal ' + icon +' row-categories-icons' " :style="'color:'+color_icon"></i>
            </div>
            <div class="col-6 col-lg-7">
                <h6 class="mb-0 text-md">{{ name }}</h6>
            </div>
            <div class="col-4 col-lg-3 text-end">
                <a v-if="edit.archived == 'No'" @click="editCategory(edit)" data-bs-toggle="modal" data-bs-target="#addCategoryModal" class="me-3">
                    <i 
                        class="fal fa-pencil text-black-50 row-categories-icons-actions"
                        v-tooltip="{
                                    content:'Editar',
                                    distance: 15
                                }"
                    >
                    </i>
                </a>
                <a v-if="edit.archived == 'No'" @click="archiveCategory(edit, 'Yes')" class="me-3">
                    <i 
                        class="fal fa-inbox-in text-black-50 row-categories-icons-actions"
                        v-tooltip="{
                                    content:'Arquivar',
                                    distance: 15
                                }"
                    >
                    </i>
                </a>
                <a v-if="edit.archived == 'Yes'" @click="archiveCategory(edit, 'No')" class="me-3">
                    <i 
                        class="fal fa-inbox-out text-black-50 row-categories-icons-actions"
                        v-tooltip="{
                                    content:'Desarquivar',
                                    distance: 15
                                }"
                    >
                    </i>
                </a>
                <a  v-if="edit.archived == 'Yes'" @click="deleteCategory(edit)" class="me-3">
                            <i 
                                class="fal fa-trash text-danger row-categories-icons-actions"
                                v-tooltip="{
                                    content:'Excluir',
                                    distance: 15
                                }"
                            >
                            </i>
                        </a>
            </div>

        </div>

        <div 
            v-for="(subcategory, index) in subs" :key="index"
            class="row align-items-center row-categories"
        >

            <div class="col-12 col-lg-11 ms-auto">

                <div class="row align-items-center">

                    <div class="col col-lg-2 ms-0 ms-lg-3 text-center">
                        <i class="fal fa-level-up fa-rotate-90 me-3 me-lg-5"></i>
                        <i :class="'fal ' + subcategory.icon +' row-categories-icons' " :style="'color:'+subcategory.color"></i>
                    </div>
                    <div class="col col-lg-4">
                        <h6 class="mb-0 text-md">{{ subcategory.name }}</h6>
                    </div>
                    <div class="col-4 text-end">
                        <a v-if="subcategory.archived == 'No'" @click="editCategory(subcategory)" data-bs-toggle="modal" data-bs-target="#addCategoryModal" class="me-3">
                            <i 
                                class="fal fa-pencil text-black-50 row-categories-icons-actions"
                                v-tooltip="{
                                    content:'Editar',
                                    distance: 15
                                }"
                                
                            >
                            </i>
                        </a>
                        <a  v-if="subcategory.archived == 'No'" @click="archiveCategory(subcategory, 'Yes')" class="me-3">
                            <i 
                                class="fal fa-inbox-in text-black-50 row-categories-icons-actions"
                                v-tooltip="{
                                    content:'Arquivar',
                                    distance: 15
                                }"
                            >
                            </i>
                        </a>
                        <a  v-if="subcategory.archived == 'Yes'" @click="archiveCategory(subcategory, 'No')" class="me-3">
                            <i 
                                class="fal fa-inbox-out text-black-50 row-categories-icons-actions"
                                v-tooltip="{
                                    content:'Desarquivar',
                                    distance: 15
                                }"
                            >
                            </i>
                        </a>
                        <a  v-if="subcategory.archived == 'Yes'" @click="deleteCategory(subcategory)" class="me-3">
                            <i 
                                class="fal fa-trash text-danger row-categories-icons-actions"
                                v-tooltip="{
                                    content:'Excluir',
                                    distance: 15
                                }"
                            >
                            </i>
                        </a>
                    </div>

                </div>

            </div>

        </div>
        

    </div>
</template>

<style>
    .row-categories{
        height: 70px;
    }

    .row-categories{
        border-bottom: solid 1px #dee2e6;
    }

    .row-categories:hover{
        background-color: #f6f9fc;
        cursor: pointer;
    }

    .row-categories-icons{
        font-size: 25px;
    }

    .row-categories-icons-actions{
        font-size: 20px;
    }
</style>