<script setup>
import { onBeforeMount, onMounted } from "vue";
import InvoiceTransactionsRow from "./components/InvoiceTransactionsRow.vue";
import setTooltip from "../assets/js/tooltip.js";
import validSession from "../assets/js/valid-session.js";
import { useStore } from "vuex";
import axios from "axios";
import bootstrap from "bootstrap/dist/js/bootstrap";
import { toast } from 'vue3-toastify';
import loadGif from "@/assets/img/loading.gif"
import 'animate.css';
import { useRoute } from "vue-router";
import CategoriesOptions from "./components/CategoriesOptions.vue";
import SkeletonLoad from "./components/SkeletonLoad.vue";

let selectCategories = {};
let meses = [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro"
    ];

const store = useStore();
const route = useRoute();

onBeforeMount(() => {

    validSession.isLogged();

    store.state.invoice_load = true;

    store.state.accounts = [];
    store.state.cards = [];
    store.state.transations = [];
    store.state.categoriesExpense = {};
    store.state.categoriesRevenue = {};
    store.state.loadingTransactions = true;
    store.state.savingTransaction = false;
    store.state.modalColor = '';

    store.state.selectedYear = new Date().getFullYear();
    store.state.selectedMonth = {
        index: new Date().getMonth(),
        number: new Date().getMonth() + 1,
        display: meses[new Date().getMonth()]
    };

    store.state.validation = {
        descHasError: false,
        descMessageError: '',
        accountError: false,
        accountMessageError: '',
        categorryError: false,
        categoryMessageError: ''
    };

    let day = new Date().getDate();
    let month = new Date().getMonth() + 1;
    let year = new Date().getFullYear();

    store.state.current_date = year+ '-' + (month < 10 ? '0'+month : month) + '-' + (day < 10 ? '0'+ day : day);

    store.state.transation = {
        type: '',
        description: '',
        amount: 0,
        date: store.state.current_date,
        account: '',
        account_name: '',
        account_destiny: '',
        account_destiny_name: '',
        category: '',
        category_name: '',
        category_icon: '',
        category_color: '',
        recurrence_checked: false,
        recurrence: '',
        recurrence_period: 'monthly',
        initial_installment: 1,
        current_installment: 1,
        end_installment: 1,
        payed: false,
        id: '',
        transaction_id: '',
        invoice_id: '',
        card_id: '',
        howUpdate: '',
        amount_paid: 0,
        ammount_unpaid: 0
    }

    store.state.card_details = {
        icon: '',
        name: '',
        id: ''
    };

    store.state.invoice_details = {
        before_amount: parseFloat(0),
        amount: parseFloat(0),
        close_date: '',
        due_date: '',
        id: '',
        order: 0,
        status: ''
    };

    store.state.invoice_id = route.params.id ? route.params.id : 0;

    getInvoice(store.state.invoice_id);

    getCards();
    getAccounts();
    getExpenseCategories();
    getRevenueCategories();

})

onMounted(() => {
  setTooltip();
});

const getInvoice = async (id) => {

    axios.get('invoices/show/'+id)
    .then(res => {
        
        if(res.status == 200){

            store.state.card_details = {
                icon: res.data.card_icon,
                name: res.data.card_name,
                id: res.data.card_id
            };

            store.state.invoice_details = {
                before_amount: parseFloat(res.data.before_amount),
                current_amount: parseFloat(res.data.current_amount),
                payed_amount: parseFloat(res.data.payed_amount),
                close_date: res.data.close_date,
                due_date: res.data.due_date,
                id: res.data.id,
                order: res.data.order,
                status: res.data.status,
                payment: res.data.payment
            };

            store.state.selectedYear = new Date(res.data.due_date).getFullYear();
            store.state.selectedMonth = {
                index: new Date(res.data.due_date).getMonth(),
                number: new Date(res.data.due_date).getMonth() + 1,
                display: meses[new Date(res.data.due_date).getMonth()]
            };

            getTransaction(res.data.id);

        }
        
    })
    .catch(err => {
        console.error(err); 
    })

}

const getInvoiceByOrder = async (order) => {

    axios.get('invoices/show-by-order?card='+store.state.card_details.id+'&order='+order)
    .then(res => {
        
        if(res.status == 200){

            store.state.card_details = {
                icon: res.data.card_icon,
                name: res.data.card_name,
                id: res.data.card_id
            };

            store.state.invoice_details = {
                before_amount: parseFloat(res.data.before_amount),
                current_amount: parseFloat(res.data.current_amount),
                payed_amount: parseFloat(res.data.payed_amount),
                close_date: res.data.close_date,
                due_date: res.data.due_date,
                id: res.data.id,
                order: res.data.order,
                status: res.data.status,
                payment: res.data.payment
            };

            store.state.selectedYear = new Date(res.data.due_date).getFullYear();
            store.state.selectedMonth = {
                index: new Date(res.data.due_date).getMonth(),
                number: new Date(res.data.due_date).getMonth() + 1,
                display: meses[new Date(res.data.due_date).getMonth()]
            };

            getTransaction(res.data.id);

            let nextURL = window.location.origin + '/fatura/'+res.data.id;
            let nextTitle = 'Agive Finanças';
            let nextState = { additionalInformation: 'Updated the URL with JS' };

            window.history.pushState(nextState, nextTitle, nextURL);
            window.history.replaceState(nextState, nextTitle, nextURL);

        }
        
    })
    .catch(err => {
        console.error(err); 
    })

}

const getTransaction = async (invoice) => {

    store.state.loadingTransactions = true;

    axios.get('transactions?invoice='+invoice)
    .then(res => {
        
        if(res.status == 200){

            store.state.transations = res.data;
            store.state.loadingTransactions = false;
            store.state.invoice_load = false;

        }
        
    })
    .catch(err => {
        console.error(err); 
    })

}

const getAccounts = async () => {

    axios.get('accounts')
    .then(res => {
        
        if(res.status == 200){

            store.state.accounts = res.data;

        }
        
    })
    .catch(err => {
        console.error(err); 
    })

}

const getCards = async () => {

    axios.get('credit-cards')
    .then(res => {
        
        if(res.status == 200){

            store.state.cards = res.data;

        }
        
    })
    .catch(err => {
        console.error(err); 
    })

}

const getExpenseCategories = async () => {

    axios.get('categories?type=despesa')
    .then(res => {
        
        if(res.status == 200){

            store.state.categoriesExpense = res.data;

        }
        
    })
    .catch(err => {
        console.error(err); 
    })

}

const getRevenueCategories = async () => {

    axios.get('categories?type=receita')
    .then(res => {

    if(res.status == 200){

        store.state.categoriesRevenue = res.data;

    }

    })
    .catch(err => {
        console.error(err); 
    })

}

const setType = (type) => {

    if(type == 'despesa'){
        selectCategories = store.state.categoriesExpense;
        store.state.modalColor = '-danger';
        store.state.transation.account = store.state.card_details.id+'-card';
    }
    else if(type == 'receita'){
        selectCategories = store.state.categoriesRevenue;
        store.state.modalColor = '-success';
        store.state.transation.amount = (store.state.invoice_details.current_amount + store.state.invoice_details.before_amount) - store.state.invoice_details.payed_amount;
        store.state.transation.payed = true;
    }
    else{
        store.state.modalColor = '-gray';
        store.state.transation.description = 'Transferência';
        store.state.transation.payed = true;
    }

    store.state.transation.type = type;

    console.log(store.state.transation.type);

}

const clearFields = () => {
    store.state.transation = {
        type: '',
        description: '',
        amount: 0,
        date: store.state.current_date,
        account: '',
        account_name: '',
        account_destiny: '',
        account_destiny_name: '',
        category: '',
        category_name: '',
        category_icon: '',
        category_color: '',
        recurrence_checked: false,
        recurrence: '',
        recurrence_period: 'monthly',
        initial_installment: 1,
        current_installment: 1,
        end_installment: 1,
        payed: false,
        id: '',
        transaction_id: '',
        invoice_id: '',
        card_id: '',
        howUpdate: '',
        amount_paid: 0,
        ammount_unpaid: 0
    }
}

const submitTransaction = async () => {

    store.state.savingTransaction = true;

    let data = new FormData();

    store.state.validation.descHasError = false;
    store.state.validation.accountError = false;
    store.state.validation.categorryError = false;

    if(store.state.transation.type == 'despesa'){
        data.append('type', store.state.transation.type);
        data.append('description', store.state.transation.description);
        data.append('amount', store.state.transation.amount);
        data.append('date', store.state.transation.date);
        data.append('account', store.state.transation.account);
        data.append('account_destiny', store.state.transation.account_destiny);
        data.append('category', store.state.transation.category);
        data.append('recurrence', store.state.transation.recurrence);
        data.append('period', store.state.transation.recurrence_period);
        data.append('initial_installment', store.state.transation.initial_installment);
        data.append('end_installment', store.state.transation.end_installment);
        data.append('payed', store.state.transation.payed ? 'Paid' : '');
    }
    else{
        data.append('type', 'despesa');
        data.append('description', 'Fatura '+store.state.card_details.name);
        data.append('amount', store.state.transation.amount);
        data.append('date', store.state.transation.date);
        data.append('account', store.state.transation.account);
        data.append('account_destiny', store.state.transation.account_destiny);
        data.append('category', 1);
        data.append('recurrence', store.state.transation.recurrence);
        data.append('period', store.state.transation.recurrence_period);
        data.append('initial_installment', store.state.transation.initial_installment);
        data.append('end_installment', store.state.transation.end_installment);
        data.append('payed', store.state.transation.payed ? 'Paid' : '');
        data.append('payment_invoice', store.state.invoice_details.id);
    }

    let url = '/transactions';
    let toast_message = 'Lançamento criado com sucesso!';

    if(store.state.transation.id != ''){

        url = '/transactions/update/'+store.state.transation.id;
        toast_message = 'Lançamento atualizado com sucesso!';

        data.append('transaction_id', store.state.transation.transaction_id);
        data.append('howUpdate', store.state.transation.howUpdate);
        data.append('invoice_id', store.state.transation.invoice_id);
    }

    await axios.post(url, data)
    .then(res => {

        if(res.data.error){

            let messages = res.data.messages;

            Object.entries(messages).forEach((value) => {

                if(value[0] == 'description'){
                    store.state.validation.descHasError = true;
                    store.state.validation.descMessageError = value[1];
                }
                else if(value[0] == 'account'){
                    store.state.validation.accountError = true;
                    store.state.validation.accountMessageError = value[1];
                }
                else if(value[0] == 'category'){
                    store.state.validation.categorryError = true;
                    store.state.validation.categoryMessageError = value[1];
                }

            })

            store.state.savingTransaction = false;

        }
        else{

            store.state.validation = {
                descHasError: false,
                descMessageError: '',
            };

            closeModal('addTransaction');

            clearFields();

            toast(toast_message, {
                autoClose: 1000,
                theme: "auto",
                type: "success",
                transition: "slide"
            });

            store.state.savingTransaction = false;

            getInvoice(store.state.invoice_details.id);

        }

    })
    .catch(err => {
        console.error(err); 

        toast('Não foi possível realizar a operação, tente mais tarde (Erro '+err.response.status+')', {
            autoClose: 1000,
            theme: "auto",
            type: "error",
            transition: "slide"
        });

        store.state.savingTransaction = false;
        store.state.invoice_load = false;

    })

}

const editTrasaction = (data) => {

    console.log(data);

    setType(data.type);

    store.state.transation = {
        type: data.type,
        description: data.description,
        amount: formatMoney(data.amount),
        date: data.date,
        account: data.card_id+'-card',
        account_name: data.account_name,
        account_destiny: data.account_destiny_id > 0 ? data.account_destiny_id+'-bank': '',
        account_destiny_name: data.account_destiny_name,
        category: data.category_id,
        category_name: data.category_name,
        category_icon: data.category_icon,
        category_color: data.category_color,
        recurrence_checked: false,
        recurrence: data.recurrence,
        recurrence_period: '',
        initial_installment: 1,
        current_installment: data.current_installment,
        end_installment: data.end_installment,
        payed: data.status == 'Unpaid' ? false : true,
        id: data.id,
        transaction_id: data.transaction_id,
        invoice_id: data.invoice_id,
        card_id: data.card_id,
        howUpdate: 'just_this',
        amount_paid: data.amount_paid,
        ammount_unpaid: data.ammount_unpaid
    }

}

const payTransation = (id, status) => {

    store.state.invoice_load = true;

    let url = '/transactions/pay/'+id;

    let data = new FormData();

    data.append('status', status == 'Paid' ? 'Unpaid' : 'Paid');

    axios.post(url, data)
    .then(res => {
        
        if(!res.data.error){

            toast('Lançamento atualizado com sucesso!', {
                autoClose: 1000,
                theme: "auto",
                type: "success",
                transition: "slide"
            });

            getTransaction();
            clearFields();

        }

    })
    .catch(err => {
        console.error(err); 
    })

}

const deleteTransation = (id, transaction_id, type) => {

    store.state.invoice_load = true;

    let url = '/transactions/delete/'+id;

    let data = new FormData();

    data.append('howDelete', type);
    data.append('transaction_id', transaction_id);

    axios.post(url, data)
    .then(res => {
        
        if(!res.data.error){

            toast('Lançamento excluido com sucesso!', {
                autoClose: 1000,
                theme: "auto",
                type: "success",
                transition: "slide"
            });

            closeModal('deleteTransaction');
            getInvoice(store.state.invoice_details.id);
            clearFields();

        }

    })
    .catch(err => {
        console.error(err); 
    })

}

const closeModal = (id) => {

    let modal = bootstrap.Modal.getOrCreateInstance(document.getElementById(id));

    modal.hide();

}

const slideDate = (direction) => {

    store.state.invoice_load = true;

    if(direction == 'prev'){

        let prev_month = store.state.selectedMonth.index > 0 ? store.state.selectedMonth.index - 1 : 11;
        let prev_year = store.state.selectedMonth.index > 0 ? store.state.selectedYear : store.state.selectedYear - 1;
        
        store.state.selectedYear = prev_year;
        store.state.selectedMonth = {
            index: prev_month,
            number: prev_month + 1,
            display: meses[prev_month]
        };

        getInvoiceByOrder(parseInt(store.state.invoice_details.order) - 1);

    }
    else if(direction == 'next'){

        let next_month = store.state.selectedMonth.index < 11 ? store.state.selectedMonth.index + 1 : 0;
        let next_year = store.state.selectedMonth.index < 11 ? store.state.selectedYear : store.state.selectedYear + 1;

        store.state.selectedYear = next_year;
        store.state.selectedMonth = {
            index: next_month,
            number: next_month + 1,
            display: meses[next_month]
        };

        getInvoiceByOrder(parseInt(store.state.invoice_details.order) + 1);

    }

}

const formatMoney = (v) => {
    let total = parseFloat(v);

    return total.toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
        });
}

const formatDate = (d) => {
    
    let date = new Date(d);
    
    return date.toLocaleDateString('pt-BR', {timeZone: 'UTC'});

}

</script>
<template>

    <div class="py-4 container-fluid">
        <div class="row">
            <div class="col-12">
        
                <div class="card">
                    <div class="card-header pb-0 sticky-top border-bottom">
                        <div class="row align-items-center mb-4">
                            <div class="col-10 col-lg-4 order-2 order-lg-1">
                                <div class="row align-items-center">
                                    <div class="col-2">
                                        <SkeletonLoad
                                            :width='"50px"'
                                            :height="'50px'"
                                            :class="'rounded'"
                                            v-if="store.state.invoice_load"
                                        />
                                        <img v-else :src="store.state.card_details.icon" alt="" width="50" class="rounded-circle">
                                    </div>
                                    <div class="col-6">
                                        <SkeletonLoad
                                            :width='"120px"'
                                            :height="'30px'"
                                            :class="'ms-1 ms-lg-0'"
                                            v-if="store.state.invoice_load"
                                        />
                                        <h4 class="ms-2" v-else>{{ store.state.card_details.name }}</h4>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-4 text-center order-1 order-lg-2 mt-1 mb-2 mt-lg-0 mb-lg-0">
                                <div class="row">
                                    <div class="col-2 col-lg-3">
                                        <a class="cursor-pointer" @click="slideDate('prev')">
                                            <i class="fal fa-chevron-left me-0 me-lg-5"></i>
                                        </a>
                                    </div>
                                    <div class="col-8 col-lg-6 mx-auto">
                                        <span>
                                            {{ store.state.selectedMonth.display }} {{ store.state.selectedYear }}
                                        </span>
                                    </div>
                                    <div class="col-2 col-lg-3">
                                        <a class="cursor-pointer" @click="slideDate('next')">
                                            <i class="fal fa-chevron-right ms-0 ms-lg-5"></i>
                                        </a>
                                    </div>
                                </div>
                                
                                <hr class="d-lg-none">
                            </div>
                            <div class="col-2 col-lg-4 order-3">
                                <div class="dropdown float-end">
                                    <a class="me-3 trasation-list-add-button mt-n1 mb-2 text-dark" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i class="fal fa-plus-circle"></i> <span class="d-none d-lg-inline-flex">Acidionar</span>
                                    </a>
                                    <ul class="dropdown-menu dropdown-menu-end text-center" aria-labelledby="dropdownMenuButton1">
                                        <li>
                                            <a class="dropdown-item" data-bs-toggle="modal" data-bs-target="#addTransaction" @click="setType('despesa')"><i class="fas fa-minus me-2">                                                
                                            </i>Despesa</a>
                                        </li>
                                    </ul>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div class="card-body px-0 pt-0 pb-2">
                        <div class="col-12">
                            <div class="row">

                                <div class="col-11 mx-auto mt-3 mb-3">

                                    <div class="row text-center mt-3">
                                        <div class="col-6 col-lg">
                                            <p class="text-sm">
                                                Saldo anterior
                                            </p>
                                            <SkeletonLoad
                                                :width='"90px"'
                                                :height="'20px'"
                                                :class="'mx-auto mt-n3 mb-3 mb-lg-0'"
                                                v-if="store.state.invoice_load"
                                            />
                                            <p v-else class="text-dark mt-n3 mb-3">
                                                <b>{{ formatMoney(store.state.invoice_details.before_amount) }}</b>
                                            </p>
                                        </div>
                                        <div class="col-6 col-lg">
                                            <p class="text-sm">
                                                Fatura do mês
                                            </p>
                                            <SkeletonLoad
                                                :width='"90px"'
                                                :height="'20px'"
                                                :class="'mx-auto mt-n3 mb-3 mb-lg-0'"
                                                v-if="store.state.invoice_load"
                                            />
                                            <p v-else class="text-dark mt-n3 mb-3">
                                                <b>{{ formatMoney((store.state.invoice_details.current_amount + store.state.invoice_details.before_amount)) }}</b>
                                            </p>
                                        </div>
                                        <div class="col-6 col-lg">
                                            <p class="text-sm">
                                                Vencimento
                                            </p>
                                            <SkeletonLoad
                                                :width='"90px"'
                                                :height="'20px'"
                                                :class="'mx-auto mt-n3 mb-3 mb-3 mb-lg-0'"
                                                v-if="store.state.invoice_load"
                                            />
                                            <p v-else class="text-dark mt-n3">
                                                <b>{{ formatDate(store.state.invoice_details.due_date) }}</b>
                                            </p>
                                        </div>
                                        <div class="col-6 col-lg">
                                            <p class="text-sm">
                                                Valor da fatura
                                            </p>
                                            <SkeletonLoad
                                                :width='"90px"'
                                                :height="'20px'"
                                                :class="'mx-auto mt-n3 mb-3 mb-3 mb-lg-0'"
                                                v-if="store.state.invoice_load"
                                            />
                                            <p v-else class="text-dark mt-n3">
                                                <b>{{ formatMoney((store.state.invoice_details.current_amount + store.state.invoice_details.before_amount)) }}</b>
                                            </p>
                                        </div>
                                    </div>

                                </div>

                                <div v-if="store.state.invoice_details.status == 'Paid'" class="col-11 mx-auto mt-3 mb-3 border border-success rounded">

                                    <div class="row text-center mt-3">
                                        <div class="col-12">
                                            <h4 class="text-sm">
                                                Pagamentos
                                            </h4>
                                            <hr>
                                        </div>
                                        <div class="col">
                                            <p class="text-sm">
                                                Data
                                            </p>
                                            <p class="text-dark mt-n3">
                                                <b>{{ formatDate(store.state.invoice_details.payment.date) }}</b>
                                            </p>
                                        </div>
                                        <div class="col">
                                            <p class="text-sm">
                                                Conta
                                            </p>
                                            <p class="text-dark mt-n3">
                                                <img :src="store.state.invoice_details.payment.account_icon" width="32" alt="">
                                                {{ store.state.invoice_details.payment.account_name }}
                                            </p>
                                        </div>
                                        
                                        <div class="col">
                                            <p class="text-sm">
                                                Valor pago
                                            </p>
                                            <p class="text-dark mt-n3">
                                                <b>{{ formatMoney(store.state.invoice_details.payed_amount) }}</b>
                                            </p>
                                        </div>

                                        <div v-if="(store.state.invoice_details.current_amount + store.state.invoice_details.before_amount) - store.state.invoice_details.payed_amount > 0 " class="col">
                                            <p class="text-sm">
                                                Valor restante
                                            </p>
                                            <p class="text-dark mt-n3">
                                                <b>{{ formatMoney((store.state.invoice_details.current_amount + store.state.invoice_details.before_amount) - store.state.invoice_details.payed_amount) }}</b>
                                                <button class="btn btn-success btn-sm ms-2 mt-2" data-bs-toggle="modal" data-bs-target="#addTransaction" @click="setType('receita')">Pagar</button>
                                            </p>
                                        </div>
                                    </div>

                                </div>

                            </div>
                            <div class="row">

                                <div class="col-11 mx-auto">
                                    <SkeletonLoad
                                        :width='"120px"'
                                        :height="'30px'"
                                        v-if="store.state.invoice_load"
                                    />
                                    <p v-else><b>{{ store.state.transations.length }} Lançamentos</b></p>
                                    <hr>
                                </div>

                                <div class="col-11 mx-auto text-center mt-4 mb-4" v-if="store.state.loadingTransactions">
                                    <div class="row">

                                        <div class="col-2 col-lg-1 ms-3 text-center">
                                            <SkeletonLoad
                                                :width='"50px"'
                                                :height="'50px'"
                                                :class="'rounded-circle'"
                                            />
                                        </div>
                                        <div class="col-6 col-lg-8">
                                            <div class="row align-items-center">
                                                <div class="col-12 col-lg-6">
                                                    <SkeletonLoad
                                                        :width="'90px'"
                                                        :height="'20px'"
                                                    />
                                                    <SkeletonLoad
                                                        :width="'90px'"
                                                        :height="'20px'"
                                                        :class="'mt-2'"
                                                    />
                                                </div>
                                                <div class="col-12 col-lg-3 text-lg-center d-none d-lg-block">
                                                    <SkeletonLoad
                                                        :width="'90px'"
                                                        :height="'20px'"
                                                    />
                                                </div>
                                                
                                            </div>
                                        </div>

                                        <div class="col-3 col-lg-2 text-end">
                                            
                                            <div class="col-12 col-lg-3 text-lg-center mt-3">
                                                <SkeletonLoad
                                                    :width="'90px'"
                                                    :height="'20px'"
                                                />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="col-12 text-center mt-4" v-if="store.state.transations.length == 0 && !store.state.loadingTransactions">
                                    <p>Nenhum lançamento cadastrado.</p>
                                </div>

                                <div class="col-11 mx-auto mb-3" v-if="!store.state.loadingTransactions">

                                    <InvoiceTransactionsRow 
                                        v-for="(transation, index) in store.state.transations" :key="index"
                                        :transaction = transation
                                        @edit="editTrasaction"
                                        @pay="payTransation"
                                    />

                                </div>

                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
    </div>

    <div class="modal fade" id="addTransaction" tabindex="-1" aria-labelledby="addTransaction" aria-hidden="true" data-bs-backdrop="static">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content border-4" :class="'border'+store.state.modalColor">
                <div class="modal-body">
                    <div class="row">
                        <div class="col-10">
                            <b>
                                {{ store.state.transation.id != '' ? 'Editar' : 'Novo' }} {{ store.state.transation.type == 'receita' ? 'Pagamento' : store.state.transation.type }}
                            </b>
                        </div>
                        <div class="col-2 text-end">
                            <a type="button" data-bs-dismiss="modal" @click="clearFields()">
                                <i class="fal fa-times-circle fa-lg"></i>
                            </a>
                        </div>

                        <div class="col-12 col-lg-11 mx-auto mt-3 mb-3">

                            <div class="row">

                                <div v-if="store.state.transation.type == 'despesa'" class="col-12 mb-3">
                                    <label for="transactionDesc">Descrição</label>
                                    <input 
                                        type="text" 
                                        class="form-control form-control-lg" 
                                        id="transactionDesc"
                                        name="transactionDesc"
                                        v-model="store.state.transation.description"
                                        :class="store.state.validation.descHasError ? 'is-invalid' : ''"
                                    >
                                    <div id="categoryNameFeedback" class="invalid-feedback">
                                        {{ store.state.validation.descMessageError }}
                                    </div>
                                </div>

                                <div class="col-6 mb-3">
                                    <label for="transactionAmount">Valor</label>
                                    <input 
                                        type="text" 
                                        class="form-control form-control-lg" 
                                        id="transactionAmount"
                                        name="transactionAmount"
                                        v-model="store.state.transation.amount"
                                        v-mask-decimal.br="2"
                                    >
                                </div>

                                <div class="col-6 mb-3">
                                    <label for="transactionDate">Data</label>
                                    <input 
                                        type="date" 
                                        class="form-control form-control-lg" 
                                        id="transactionDate"
                                        name="transactionDate"
                                        v-model="store.state.transation.date"
                                    >
                                </div>

                                <div class="col-6 mb-3">
                                    <label for="transactionAccount">Conta/Cartão</label>
                                    <select 
                                        class="form-select form-select-lg" 
                                        aria-label="Conta/Cartão" 
                                        v-model="store.state.transation.account"
                                        id="transactionAccount"
                                        :class="store.state.validation.accountError ? 'is-invalid' : ''"
                                    >
                                            <option value="" selected disabled>Selecione uma opção</option>
                                            <optgroup label="Contas">
                                                <option
                                                    v-for="(account, index) in store.state.accounts" :key="index"
                                                    :value="account.id+'-bank'"
                                                >
                                                    {{ account.name }}
                                                </option>
                                            </optgroup>

                                            <optgroup label="Cartões" v-if="store.state.transation.type == 'despesa'">
                                                <option
                                                    v-for="(card, index) in store.state.cards" :key="index"
                                                    :value="card.id+'-card'"
                                                >
                                                    {{ card.name }}
                                                </option>
                                            </optgroup>
                                    </select>
                                    <div class="invalid-feedback">
                                        {{ store.state.validation.accountMessageError }}
                                    </div>
                                </div>

                                <div v-if="store.state.transation.type == 'despesa'" class="col-6 mb-3">
                                    <label for="categoryName">Categoria</label>
                                    <select 
                                        class="form-select form-select-lg" 
                                        aria-label="Categoria" 
                                        v-model="store.state.transation.category"
                                        id="parentSelect"
                                        :class="store.state.validation.categorryError ? 'is-invalid' : ''"
                                    >
                                            <option value="" selected disabled>Selecione a categoria</option>
                                            <CategoriesOptions :categories="selectCategories"></CategoriesOptions>
                
                                    </select>
                                    <div id="parentSelectFeedback" class="invalid-feedback">
                                        {{ store.state.validation.categoryMessageError }}
                                    </div>
                                </div>

                                <div v-if="store.state.transation.id == '' && store.state.transation.type == 'despesa'" class="col-12 mb-3">
                                    <div class="form-check form-switch">
                                        <input class="form-check-input" type="checkbox" id="transactionRecurrence" v-model="store.state.transation.recurrence_checked">
                                        <label class="form-check-label" for="transactionRecurrence">Repetir lançamento</label>
                                    </div>
                                </div>

                                <div v-if="store.state.transation.recurrence_checked && store.state.transation.id == ''" class="col-12 mb-3">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" name="transactionRecurrenceType" id="transactionRecurrenceType1" value="Fixed" v-model="store.state.transation.recurrence">
                                        <label class="form-check-label" for="transactionRecurrenceType1">
                                            É uma {{ store.state.transation.type }} fixa
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" name="transactionRecurrenceType" id="transactionRecurrenceType2" value="Installments" v-model="store.state.transation.recurrence">
                                        <label class="form-check-label" for="transactionRecurrenceType2">
                                            É um {{ store.state.transation.type }} parcelado
                                        </label>
                                    </div>
                                </div>

                                <div v-if="store.state.transation.recurrence_checked && store.state.transation.recurrence == 'Installments' && store.state.transation.id == ''" class="col-6 mb-3">

                                    <label for="transactionInitialI">Parcela inicial</label>
                                    <select class="form-select form-select-lg"
                                        v-model="store.state.transation.initial_installment"
                                    >
                                        <option v-for="n in 360" :key="n"
                                            :value="n"
                                        >
                                            {{n}}
                                        </option>
                                    </select>

                                </div>

                                <div v-if="store.state.transation.recurrence_checked && store.state.transation.recurrence == 'Installments' && store.state.transation.id == ''" class="col-6 mb-3">

                                    <label for="transactionInitialI">Quantidade</label>
                                    <select class="form-select form-select-lg"
                                        v-model="store.state.transation.end_installment"
                                    >
                                        <option v-for="n in 360" :key="n"
                                            :value="n"
                                        >
                                            {{n}}
                                        </option>
                                    </select>

                                </div>

                                <div v-if="store.state.transation.recurrence_checked && store.state.transation.id == '' && (store.state.transation.recurrence == 'Fixed' || store.state.transation.recurrence == 'Installments')" class="col-12 mb-3">

                                    <label for="transactionPeriod">Periodicidade</label>
                                    <select class="form-select form-select-lg"
                                        v-model="store.state.transation.recurrence_period"
                                    >
                                        <option value="">Selecione uma opção</option>
                                        <option value="day">Diário</option>
                                        <option value="weekley">Semanal</option>
                                        <option value="biweekly">Quinzenal</option>
                                        <option value="monthly">Mensal</option>
                                        <option value="quarterly">Trimestral</option>
                                        <option value="semi-annual">Semestral</option>
                                        <option value="annual">Anual</option>
                                    </select>

                                </div>

                                <div v-if="store.state.transation.id != '' && store.state.transation.recurrence != ''" class="col-11 mx-auto mb-3 bg-light rounded">

                                    <p class="text-danger fw-bold mt-2">
                                        Este lançamento se repete em outras datas.
                                    </p>
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" name="transactionUpdateOthers" id="transactionUpdateOthers1" value="just_this" v-model="store.state.transation.howUpdate">
                                        <label class="form-check-label" for="transactionUpdateOthers1">
                                            Atualizar apenas este lançamento
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" name="transactionUpdateOthers" id="transactionUpdateOthers2" value="this_after" v-model="store.state.transation.howUpdate">
                                        <label class="form-check-label" for="transactionUpdateOthers2">
                                            Atualizar este e os próximos
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" name="transactionUpdateOthers" id="transactionUpdateOthers3" value="all" v-model="store.state.transation.howUpdate">
                                        <label class="form-check-label" for="transactionUpdateOthers3">
                                            Atualizar todos os lançamentos
                                        </label>
                                    </div>
                                
                                </div>

                                <div class="col-12 text-center mt-4 mb-n4">
                                    <button
                                        v-if="!store.state.savingTransaction"
                                        @click="submitTransaction()"
                                        class="btn"
                                        :class="'btn'+store.state.modalColor"
                                    >
                                        Salvar
                                    </button>
                                    <img v-if="store.state.savingTransaction" :src="loadGif" alt="" width="50">
                                </div>
                                
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="detailsTransaction" tabindex="-1" aria-labelledby="detailsTransaction" aria-hidden="true" data-bs-backdrop="static">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content border-4" :class="'border'+store.state.modalColor">

                <div class="modal-body">

                    <div class="row align-items-center">
                        <div class="col-4 text-center">
                            <i :class="'fal ' +  store.state.transation.category_icon +' row-transactions-icons fa-3x' " :style="'color:'+store.state.transation.category_color"></i>
                            <br>
                            <b>{{ store.state.transation.description }}</b>
                            <br>
                            <b :class="'text'+store.state.modalColor">{{store.state.transation.type == 'despesa' ? '-': ''}} {{ store.state.transation.amount }}</b>
                        </div>
                        <div class="col-6">
                            <p class="text-dark text-sm fw-bold">
                                <span class="text-muted">Data</span>
                                <br>
                                {{ formatDate(store.state.transation.date) }}
                            </p>
                            <p class="text-dark text-sm fw-bold">
                                <span class="text-muted mt-2">Conta</span>
                                <br>
                                {{ store.state.transation.account_name }}
                            </p>
                            <p class="text-dark text-sm fw-bold">
                                <span class="text-muted">Categoria</span>
                                <br>
                                {{ store.state.transation.category_name }}
                            </p>
                        </div>
                        <div class="col-2 text-end align-self-baseline">
                            <a type="button" data-bs-dismiss="modal" @click="clearFields()">
                                <i class="fal fa-times-circle fa-lg"></i>
                            </a>
                        </div>
                        <hr>
                    </div>

                    <div v-if="store.state.transation.recurrence == 'Installments'" class="row text-center align-items-center">
                        <div class="col-4">
                            <p class="fw-bold">
                                Parcela {{store.state.transation.current_installment}}/{{store.state.transation.end_installment}}
                            </p>
                        </div>
                        <div class="col-4">
                            <p class="text-dark text-sm fw-bold">
                                <span class="text-muted">{{store.state.transation.type == 'despesa' ? 'Já paguei': 'Já recebi'}}</span>
                                <br>
                                {{ formatMoney(store.state.transation.amount_paid) }}
                            </p>
                        </div>
                        <div class="col-4">
                            <p class="text-dark text-sm fw-bold">
                                <span class="text-muted">{{store.state.transation.type == 'despesa' ? 'Falta pagar': 'Falta receber'}}</span>
                                <br>
                                <span class="text-danger">{{ formatMoney(store.state.transation.ammount_unpaid) }}</span>
                            </p>
                        </div>
                        <hr>
                    </div>

                    <div class="row">
                        <div class="col-12">
                                                      
                            <a data-bs-toggle="modal" data-bs-target="#deleteTransaction" class="float-end">
                                <i 
                                    class="fal fa-trash text-danger row-transactions-icons-actions cursor-pointer"
                                    v-tooltip="{
                                                content:'Excluir',
                                                distance: 15
                                            }"
                                >
                                </i>
                            </a>
                            <a data-bs-toggle="modal" data-bs-target="#addTransaction" class="me-3 float-end">
                                <i 
                                    class="fal fa-pencil text-black-50 row-transactions-icons-actions cursor-pointer"
                                    v-tooltip="{
                                                content:'Editar',
                                                distance: 15
                                            }"
                                    
                                >
                                </i>
                            </a>
                        </div>
                    </div>

                </div>
            
            </div>
        </div>
    </div>

    <div class="modal fade" id="deleteTransaction" tabindex="-1" aria-labelledby="deleteTransactionModal" aria-hidden="true" data-bs-backdrop="static">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body">
                    <div class="col-11 mx-auto mt-4 mb-4">
                        <h5 class="mb-4">Você tem certeza que deseja excluir o lançamento <b>"{{ store.state.transation.description }}"</b>?</h5>
                    </div>
                    <div 
                        class="col-11 mx-auto text-center"
                    >

                        <div  v-if="store.state.transation.recurrence != ''" class="d-grid gap-2">
                            <button class="btn btn-light" type="button" @click="deleteTransation(store.state.transation.id, store.state.transation.transaction_id, 'just_this')">Excluir apenas este lançamento</button>
                            <button class="btn btn-danger" type="button" @click="deleteTransation(store.state.transation.id, store.state.transation.transaction_id, 'this_after')">Excluir este e os próximos</button>
                        </div>

                        <a  v-if="store.state.transation.recurrence != ''" @click="closeModal('deleteTransaction')" class="btn btn-link">Cancelar</a>

                        <div  v-if="store.state.transation.recurrence == ''" class="d-grid gap-2">
                            <button class="btn btn-danger" type="button" @click="deleteTransation(store.state.transation.id, store.state.transation.transaction_id, 'just_this')">Sim</button>
                            <button @click="closeModal('deleteTransaction')" class="btn btn-light" type="button">Não</button>
                        </div>

                    </div>
                </div>
                
            </div>
        </div>
    </div>

    <div class="modal fade" id="payTransaction" tabindex="-1" aria-labelledby="payTransaction" aria-hidden="true" data-bs-backdrop="static">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content border-4" :class="'border'+store.state.modalColor">
                <div class="modal-body">
                    <div class="row">
                        <div class="col-10">
                            <b>
                                Realizar pagamento
                            </b>
                        </div>
                        <div class="col-2 text-end">
                            <a type="button" data-bs-dismiss="modal">
                                <i class="fal fa-times-circle fa-lg"></i>
                            </a>
                        </div>

                    </div>

                    <div class="row align-items-center">
                        <div class="col-4 text-center">
                            <i :class="'fal fa-receipt row-transactions-icons fa-3x' " :style="'color:#000'"></i>
                            <br>
                            <b>{{ store.state.transation.description }}</b>
                            <br>
                        </div>
                        <div class="col-6">

                            <div class="row">

                                <div class="col-12 mb-3">
                                    <label for="transactionAmount">Valor</label>
                                    
                                    <input 
                                        type="text" 
                                        class="form-control form-control-lg" 
                                        id="transactionAmount"
                                        name="transactionAmount"
                                        v-model="store.state.transation.amount"
                                        v-mask-decimal.br="2"
                                    >
                                </div>

                                <div class="col-12 mb-3">
                                    <label for="transactionDate">Data</label>
                                    <input 
                                        type="date" 
                                        class="form-control form-control-lg" 
                                        id="transactionDate"
                                        name="transactionDate"
                                        v-model="store.state.transation.date"
                                    >
                                </div>

                                <div v-if="store.state.transation.type == 'receita' || store.state.transation.type == 'despesa'" class="col-12 mb-3">
                                    <label for="transactionAccount">Conta/Cartão</label>
                                    <select 
                                        class="form-select form-select-lg" 
                                        aria-label="Conta/Cartão" 
                                        v-model="store.state.transation.account"
                                        id="transactionAccount"
                                    >
                                            <option value="" selected disabled>Selecione uma opção</option>
                                            <optgroup label="Contas">
                                                <option
                                                    v-for="(account, index) in store.state.accounts" :key="index"
                                                    :value="account.id+'-bank'"
                                                >
                                                    {{ account.name }}
                                                </option>
                                            </optgroup>

                                            <optgroup label="Cartões">
                                                <option
                                                    v-for="(card, index) in store.state.cards" :key="index"
                                                    :value="card.id+'-card'"
                                                >
                                                    {{ card.name }}
                                                </option>
                                            </optgroup>
                                    </select>
                                    <div id="parentSelectFeedback" class="invalid-feedback">
                                        {{ store.state.validation.accountMessageError }}
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12 text-center mb-2">
                            <button
                                v-if="!store.state.savingTransaction"
                                @click="submitTransaction()"
                                class="btn"
                                :class="'btn'+store.state.modalColor"
                            >
                                {{store.state.transation.type == 'despesa' ? 'Pagar': 'Receber'}}
                            </button>
                            <img v-if="store.state.savingTransaction" :src="loadGif" alt="" width="50">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<style>

.trasation-list-add-button{
    font-size: 18px;
}

.btn-gray{
    background-color: #67748e !important;
    color: #fff !important;
}

@media (max-width: 575.98px) {

    .trasation-list-add-button{
            font-size: 22px;
        }
}

</style>