<script setup>
import { onBeforeMount, toRefs } from "vue";
import { useStore } from "vuex";

    const props = defineProps({
        categories: {
            type: Object,
            required: true
        }
    });

    const store = useStore();

    onBeforeMount(() => {

        const { categories } = toRefs(props);

        store.state.allCategories = [];

        categories.value.forEach(element => {
        
            store.state.allCategories.push(element);

            if(element.subs.length > 0){
                element.subs.forEach(sub => {

                    sub.parent_name = element.name;

                    store.state.allCategories.push(sub)

                });
            }

        });

    });
    
</script>

<template>
    <option
        v-for="(category, index) in store.state.allCategories" :key="index"
        :value="category.id"
        style="font-family: 'FontAwesome', sans-serif; font-weight: 300;"
    >
        {{ category.sub == 'Yes' ? '&#xf178;' : ''}} {{ category.name }}
    </option>
</template>