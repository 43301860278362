<script setup>
import { onBeforeMount} from "vue";
import { useStore } from "vuex";
import axios from "axios";
import { toast } from 'vue3-toastify';

const store = useStore();

const getData = async () => {

  axios.get('users/data')
  .then(res => {
      
      if(res.status == 200){

          store.state.user = res.data;

      }
      
  })
  .catch(err => {
      console.error(err); 
  })

}

onBeforeMount(() => {

  store.state.user = {
    name: '',
    email: '',
    birthdate: '',
    sex: '',
    cep: '',
    street: '',
    neighborhood: '',
    city: '',
    state: '',
    number: '',
    password: '',
    new_password: '',
    password_confirmation: ''
  };

  store.state.validation = {
        nameHasError: false,
        nameMessageError: '',
        passwordHasError: false,
        passwordMessageError: '',
        newPasswordHasError: false,
        newPasswordMessageError: '',
        passwordConfirmationHasError: false,
        passwordConfirmationMessageError: '',        
    };

  getData();
});

const getCep = async () => {

  if(store.state.user.cep.length == 8){

    axios.get('https://viacep.com.br/ws/'+store.state.user.cep+'/json/')
    .then(res => {

      if(res.status == '200'){

        store.state.user.street = res.data.logradouro;
        store.state.user.neighborhood = res.data.bairro;
        store.state.user.city = res.data.localidade;
        store.state.user.state = res.data.uf;

        document.getElementById('house-number').focus();

      }
      
    })
    .catch(err => {
      console.error(err); 
    })

  }  

}

const updateUser = async () => {

  store.state.validation = {
        nameHasError: false,
        nameMessageError: '',
        passwordHasError: false,
        passwordMessageError: '',
        newPasswordHasError: false,
        newPasswordMessageError: '',
        passwordConfirmationHasError: false,
        passwordConfirmationMessageError: '',        
    };

  let url = '/users/update';

  let data = new FormData();

  data.append('name', store.state.user.name);
  data.append('birthdate', store.state.user.birthdate);
  data.append('sex', store.state.user.sex);
  data.append('cep', store.state.user.cep);
  data.append('street', store.state.user.street);
  data.append('number', store.state.user.number);
  data.append('neighborhood', store.state.user.neighborhood);
  data.append('city', store.state.user.city);
  data.append('state', store.state.user.state);
  data.append('password', store.state.user.password);
  data.append('new_password', store.state.user.new_password);
  data.append('password_confirmation', store.state.user.password_confirmation);
  
  axios.post(url, data)
  .then(res => {
    console.log(res)

    if(res.data.error){

      let messages = res.data.messages;

      Object.entries(messages).forEach((value) => {

          if(value[0] == 'name'){
              store.state.validation.nameHasError = true;
              store.state.validation.nameMessageError = value[1];
          }
          else if(value[0] == 'password'){
              store.state.validation.passwordHasError = true;
              store.state.validation.passwordMessageError = value[1];
          }
          else if(value[0] == 'new_password'){
              store.state.validation.newPasswordHasError = true;
              store.state.validation.newPasswordMessageError = value[1];
          }
          else if(value[0] == 'password_confirmation'){
              store.state.validation.passwordConfirmationHasError = true;
              store.state.validation.passwordConfirmationMessageError = value[1];
          }
          else if(value[1] == 'Senha inválida'){
            store.state.validation.passwordHasError = true;
            store.state.validation.passwordMessageError = value[1];
          }

      })

      store.state.savingTransaction = false;

    }
    else{

      toast(res.data.message, {
                autoClose: 1000,
                theme: "auto",
                type: "success",
                transition: "slide"
            });

    }

  })
  .catch(err => {
    console.error(err); 
  })

}

</script>
<template>
  <main>
    
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <p class="text-uppercase text-sm">Meus dados</p>
              <div class="row">
                <div class="col-md-6">
                  <label for="example-text-input" class="form-control-label">Nome</label>
                  <input class="form-control" type="text" v-model="store.state.user.name" :class="store.state.validation.nameHasError ? 'is-invalid' : ''"/>
                  <div class="invalid-feedback">
                      {{ store.state.validation.nameMessageError }}
                  </div>
                </div>
                <div class="col-md-6">
                  <label for="example-text-input" class="form-control-label">E-mail</label>
                  <input class="form-control" type="email" v-model="store.state.user.email" readonly/>
                </div>
                <div class="col-md-6">
                  <label for="example-text-input" class="form-control-label">Data de Nascimento</label
                  >
                  <input class="form-control" type="date" v-model="store.state.user.birthdate" />
                </div>
                <div class="col-md-6">
                  <label for="example-text-input" class="form-control-label">Sexo</label>
                  <select class="form-select" v-model="store.state.user.sex">
                    <option value="" disabled>Selecionar uma opção</option>
                    <option value="M">Masculino</option>
                    <option value="F">Feminino</option>
                    <option value="N">Prefiro não informar</option>
                  </select>
                </div>
              </div>
              <hr class="horizontal dark" />
              <p class="text-uppercase text-sm">Informações Adicionais</p>
              <div class="row">
                <div class="col-md-4">
                  <label for="example-text-input" class="form-control-label">CEP</label>
                  <input class="form-control" type="text" v-model="store.state.user.cep" maxlength="8" @keyup="getCep()" placeholder="00000000"/>
                </div>
                <div class="col-md-8">
                  <label for="example-text-input" class="form-control-label">Logradouro</label>
                  <input class="form-control"
                    type="text"
                    v-model="store.state.user.street"
                  />
                </div>
                <div class="col-md-3">
                  <label for="example-text-input" class="form-control-label">Número</label>
                  <input class="form-control" id="house-number" type="text" v-model="store.state.user.number" />
                </div>
                <div class="col-md-3">
                  <label for="example-text-input" class="form-control-label">Bairro</label>
                  <input class="form-control" type="text" v-model="store.state.user.neighborhood" />
                </div>
                <div class="col-md-3">
                  <label for="example-text-input" class="form-control-label">Cidade</label>
                  <input class="form-control" type="text" v-model="store.state.user.city" />
                </div>
                <div class="col-md-3">
                  <label for="example-text-input" class="form-control-label">Estado</label>
                  <input class="form-control" type="text" v-model="store.state.user.state" />
                </div>
                
              </div>
              <hr class="horizontal dark" />
              <p class="text-uppercase text-sm">Segurança</p>
              <div class="row">
                <div class="col-md-12">
                  <label for="example-text-input" class="form-control-label">Senha Atual</label>
                  <input class="form-control"
                    type="password"
                    v-model="store.state.user.password"
                    :class="store.state.validation.passwordHasError ? 'is-invalid' : ''"
                  />
                  <div class="invalid-feedback">
                      {{ store.state.validation.passwordMessageError }}
                  </div>
                </div>
                <div class="col-md-6">
                  <label for="example-text-input" class="form-control-label">Nova Senha</label>
                  <input class="form-control" type="password" v-model="store.state.user.new_password" :class="store.state.validation.newPasswordHasError ? 'is-invalid' : ''"/>
                  <div class="invalid-feedback">
                      {{ store.state.validation.newPasswordMessageError }}
                  </div>
                </div>
                <div class="col-md-6">
                  <label for="example-text-input" class="form-control-label">Confirmar Senha</label>
                  <input class="form-control" type="password" v-model="store.state.user.password_confirmation" :class="store.state.validation.passwordConfirmationHasError ? 'is-invalid' : ''"/>
                  <div class="invalid-feedback">
                      {{ store.state.validation.passwordConfirmationMessageError }}
                  </div>
                </div>

                <div class="col-12 text-end mt-3">
                  <button class="btn btn-success" @click="updateUser()">Salvar</button>
                </div>

              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
