<script setup>
defineProps({
    name: {
        type: String,
        required: true
    },
    limit: {
        type: Number,
        required: true,
    },
    value: {
        type: Number,
        required: true,
    },
    before_value: {
        type: Number,
        required: false,
    },
    due_date: {
        type: String,
        default: "",
    },
    status: {
        type: String,
        default: "text-success"
    },
    id: {
        type: String,
        default: "",
    },
    icon: {
        type: String,
        default: "",
    },
    sum: {
        type: Number,
        required: false
    },
    fixes: {
        type: Number,
        required: false
    }
    
});

function formatMoney(v) {
      let total = parseFloat(v);

      return total.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      });
    }
</script>

<template>
    <div class="row align-items-center row-cards">

        <div class="col-6">
            
            <div class="row align-items-center">
                <div class="col-3 col-lg-2 ms-3">
                    <img :src="icon" alt="Banco Logo" width="50" class="rounded-circle">
                </div>
                <div class="col-7 col-lg-5">
                    <h6 class="ms-3">{{ name }}</h6>
                </div>
            </div>

        </div>

        <div class="col-6 text-end">
            <a :href="'/fatura/'+id" class="me-4 btn btn-light btn-sm">
                Ver fatura
            </a>
        </div>

        <div class="col-11 mx-auto border-bottom mt-n4 mt-lg-n5">

            <div class="row text-center p-2">

                <div class="col-6">
                    <p class="mb-n1 card-title">Limite Disponível</p>
                    <h6>{{ formatMoney(parseFloat(limit) - (parseFloat(sum) - parseFloat(fixes))) }}</h6>
                </div>
                <div class="col-6">
                    <p class="mb-n1 card-title">Fatura Atual</p>
                    <h6>{{ formatMoney((parseFloat(value) + parseFloat(before_value))) }}</h6>
                </div>

            </div>

        </div>

    </div>
</template>

<style>
    .row-cards{
        height: 170px;
    }

    .card-title{
            font-size: 14px;
        }

    @media (max-width: 575.98px) {
        .card-title{
            font-size: 13px;
        }
    }
</style>