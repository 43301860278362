<script setup>
defineProps({
    transaction: {
        type: Object
    }
});

function formatMoney(v) {
    let total = parseFloat(v);

    return total.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
    });
}

function formatDate(d){
    
    let date = new Date(d);
    
    return date.toLocaleDateString('pt-BR', {timeZone: 'UTC'});

}

const emit = defineEmits(['pay']);

const payTrasaction = (data) => {
    emit('pay', data);
}

const is_taled = (date, state) => {
    let data = new Date(date);

    if(data.setDate(data.getDate() + 1) < new Date() && state == 'Unpaid'){
        return 'transaction-taled';
    }
}
</script>

<template>
    <div class="col-12 border-bottom" :class="is_taled(transaction.date, transaction.status)">

        <div class="row align-items-center row-transactions">

            <div class="col-2 col-lg-1 ms-3 text-center" v-tooltip="{
                            content: transaction.category_name,
                            distance: 15
                        }">
                <i :class="'fal ' + transaction.category_icon +' row-transactions-icons' " :style="'color:'+transaction.category_color"></i>
            </div>
            <div class="col-7 col-lg-8">
                <div class="row align-items-center">
                    <div class="col-12 col-lg-6">
                        <p class="mb-1 text-sm">
                            {{ formatDate(transaction.date) }}
                        </p>
                        <h6 class="mb-0 text-md">{{ transaction.description }}</h6>
                    </div>
                    <div class="col-12 col-lg-6 text-lg-center">
                        <h6 class="mb-0 text-md" :class="transaction.type == 'despesa' ? 'text-danger' : transaction.type == 'receita' ? 'text-success' : ''">{{transaction.type == 'despesa' ? '-': ''}} {{ formatMoney(transaction.amount) }}</h6>
                    </div>
                </div>
            </div>
            
            <div class="col-2 text-end">
                <a @click="payTrasaction(transaction)" class="me-3 cursor-pointer">
                    <i 
                        class="fal fa-thumbs-up text-black-50 row-transactions-icons-actions"
                        v-tooltip="{
                            content: transaction.type == 'despesa' ? transaction.status == 'Unpaid' ? 'Pagar' : 'Pago' : transaction.status == 'Unpaid' ? 'Receber' : 'Recebido',
                            distance: 15
                        }"
                    ></i>
                </a>
            </div>
            
            

        </div>

    </div>
</template>

<style>

    .row-transactions-icons{
        font-size: 25px;
    }

    .row-transactions-icons-actions{
        font-size: 20px;
    }
</style>