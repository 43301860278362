<script setup>
import MiniStatisticsCard from "@/examples/Cards/MiniStatisticsCard.vue";
import Accounts from "./components/Accounts.vue";
import Cards from "./components/Cards.vue";
import Transactions from "./components/Transactions.vue";
import { onBeforeMount, onMounted } from "vue";
import router from "../router";
import cookie from '../assets/js/cookies.js';
import validSession from "../assets/js/valid-session.js";
import { useStore } from "vuex";
import axios from "axios";
import SkeletonLoad from "./components/SkeletonLoad.vue";
import { toast } from 'vue3-toastify';


const store = useStore();

onBeforeMount(() => {

  validSession.isLogged();

  store.state.loading_values = true;
  store.state.values = {
    balance: 0,
    in: 0,
    out: 0,
    cc: 0,
  }

  store.state.loading_accounts = true;
  store.state.accounts = [];

  store.state.loading_cards = true;
  store.state.cards = [];

  store.state.loading_transactions = true;
  store.state.transactions_in = [];
  store.state.transactions_out = [];

  store.state.transation = {
      type: '',
      description: '',
      amount: 0,
      date: store.state.current_date,
      account: '',
      account_name: '',
      account_destiny: '',
      account_destiny_name: '',
      category: '',
      category_name: '',
      category_icon: '',
      category_color: '',
      recurrence_checked: false,
      recurrence: '',
      recurrence_period: 'monthly',
      initial_installment: 1,
      current_installment: 1,
      end_installment: 1,
      payed: false,
      id: '',
      transaction_id: '',
      invoice_id: '',
      card_id: '',
      howUpdate: '',
      amount_paid: 0,
      ammount_unpaid: 0
  }

  getValues();
  getAccounts();
  getCards();
  getTransaction();
    
})

onMounted(() => {

  let session = cookie.get('user_agive_session');

  if(session == null){
    router.push({name: 'Login'});
  }

});

const getValues = async () => {

  store.state.loading_values = true;

  axios.get('dashboard/values')
    .then(res => {
        
        if(res.status == 200){

            store.state.values = {
              balance: res.data.balance ? res.data.balance : 0,
              in: res.data.in ? res.data.in : 0,
              out: res.data.out ? res.data.out : 0,
              cc: res.data.cc ? res.data.cc : 0,
            }

            store.state.loading_values = false;

        }
        
    })
    .catch(err => {
        console.error(err); 
    })

}

const getAccounts = async () => {

  axios.get('accounts')
  .then(res => {
      
      if(res.status == 200){

          store.state.accounts = res.data;
          store.state.loading_accounts = false;

      }
      
  })
  .catch(err => {
      console.error(err); 
  })

}

const getCards = async () => {

  axios.get('credit-cards')
  .then(res => {
      
      if(res.status == 200){

          store.state.cards = res.data;
          store.state.loading_cards = false;

      }
      
  })
  .catch(err => {
      console.error(err); 
  })

}

const getTransaction = async () => {

  store.state.loading_transactions = true;

  axios.get('dashboard/transactions')
    .then(res => {
        
        if(res.status == 200){

            store.state.transactions_in = res.data.in;
            store.state.transactions_out = res.data.out;

            store.state.loading_transactions = false;

        }
        
    })
    .catch(err => {
        console.error(err); 
    })

}

const payTransation = (transaction) => {

  let url = '/transactions/pay/'+transaction.id;

  let data = new FormData();

  data.append('status', transaction.status == 'Paid' ? 'Unpaid' : 'Paid');

  axios.post(url, data)
  .then(res => {
      
      if(!res.data.error){

          toast('Lançamento atualizado com sucesso!', {
              autoClose: 1000,
              theme: "auto",
              type: "success",
              transition: "slide"
          });

          getTransaction();

      }

  })
  .catch(err => {
      console.error(err); 
  })


}

</script>
<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-lg-12">

        <div class="row">
          <div class="col-lg-3 col-md-6 col-12">
            <mini-statistics-card
              :title="{
                text: 'Saldo Geral',
                color: ''
              }"
              :value="{
                text: store.state.values.balance,
                color: ''
              }"
              description=""
              :icon="{
                component: 'fal fa-wallet',
                background: 'bg-gradient-primary',
                shape: 'rounded-circle',
              }"
              :loading="store.state.loading_values"
            />
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <mini-statistics-card
              :title="{
                text: 'Receita Mensal',
                color: ''
              }"
              :value="{
                text: store.state.values.in,
                color: ''
              }"
              description=""
              :icon="{
                component: 'fal fa-plus',
                background: 'bg-gradient-success',
                shape: 'rounded-circle',
              }"
              :loading="store.state.loading_values"
            />
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <mini-statistics-card
              :title="{
                text: 'Despesa Mensal',
                color: ''
              }"
              :value="{
                text: store.state.values.out,
                color: ''
              }"
              description=""
              :icon="{
                component: 'fal fa-minus',
                background: 'bg-gradient-danger',
                shape: 'rounded-circle',
              }"
              :loading="store.state.loading_values"
            />
          </div>
          <div class="col-lg-3 col-md-6 col-12">

            <mini-statistics-card
              :title="{
                text: 'Cartões de crédito',
                color: ''
              }"
              :value="{
                text: store.state.values.cc,
                color: ''
              }"
              description=""
              :icon="{
                component: 'fal fa-credit-card',
                background: 'bg-gradient-info',
                shape: 'rounded-circle',
              }"
              :loading="store.state.loading_values"
            />
            
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6 mb-lg-0">

            <div class="row">

              <!--Linha minhas contas-->
              <div class="col-lg-12">

                <div class="card">
                  <div class="p-3 pb-0 card-header">
                    <div class="d-flex justify-content-between">
                      <h6 class="mb-2">Minhas Contas</h6>
                    </div>
                  </div>
                  <div v-if="store.state.loading_accounts" class="col-12">
                    <div class="col-12">

                      <div class="row align-items-center row-accounts">

                          <div class="col-2 col-lg-1 ms-3">
                            <SkeletonLoad
                                :width="'45px'"
                                :height="'45px'"
                            />
                          </div>
                          <div class="col-3 col-lg-4">
                            <SkeletonLoad
                                :width="'90px'"
                                :height="'25px'"
                                :class="'ms-2'"
                            />
                          </div>
                          <div class="col-5 col-lg-6 text-end">
                            <SkeletonLoad
                                :width="'90px'"
                                :height="'25px'"
                                :class="'float-end'"
                            />
                          </div>

                      </div>

                    </div>
                  </div>
                  <div v-else class="col-12">
                    <Accounts
                      v-for="(account, index) in store.state.accounts" :key="index"
                      :bank="account.name"
                      :balance="account.balance"
                      :id="account.id"
                      :icon="account.icon"
                    />
                  </div>
                </div>

              </div>

              <!--Linha Cartões-->
              <div class="col-lg-12 mt-4 mb-4 mb-lg-0">

                <div class="card">
                  <div class="p-3 pb-0 card-header">
                    <div class="d-flex justify-content-between">
                      <h6 class="mb-2">Meus Cartões</h6>
                    </div>
                  </div>
                  <div v-if="store.state.loading_cards" class="col-12">
                    <div class="row align-items-center row-cards">

                      <div class="col-6">
                          
                          <div class="row align-items-center">
                              <div class="col-3 col-lg-2 ms-3">
                                <SkeletonLoad
                                    :width="'45px'"
                                    :height="'45px'"
                                />
                              </div>
                              <div class="col-7 col-lg-5">
                                  <SkeletonLoad
                                      :width="'90px'"
                                      :height="'20px'"
                                      :class="'ms-2'"
                                  />
                                  <SkeletonLoad
                                      :width="'90px'"
                                      :height="'15px'"
                                      :class="'ms-2 mt-1'"
                                  />
                              </div>
                          </div>

                      </div>

                      <div class="col-6 text-end">
                        <SkeletonLoad
                            :width="'90px'"
                            :height="'30px'"
                            :class="'float-end me-4'"
                        />
                      </div>

                      <div class="col-11 mx-auto border-bottom mt-n4 mt-lg-n5">

                          <div class="row text-center p-2">

                              <div class="col-6">
                                  <p class="mb-n1 card-title">Limite Disponível</p>
                                  <SkeletonLoad
                                      :width="'90px'"
                                      :height="'20px'"
                                      :class="'mx-auto mt-1'"
                                  />
                              </div>
                              <div class="col-6">
                                  <p class="mb-n1 card-title">Fatura Atual</p>
                                  <SkeletonLoad
                                      :width="'90px'"
                                      :height="'20px'"
                                      :class="'mx-auto mt-1'"
                                  />
                              </div>

                          </div>

                      </div>

                    </div>
                  </div>
                  <div v-else class="col-12">

                    <Cards
                     v-for="(card, index) in store.state.cards" :key="index"
                     :name="card.name"
                     :limit="card.limit"
                     :value="card.current_invoice.current_amount"
                     :before_value="card.current_invoice.before_amount"
                     :due_date="card.current_invoice.due_date"
                     :icon="card.icon"
                     :id="card.current_invoice.id"
                     :status="card.current_invoice.status"
                     :sum="card.sum[0].current_amount"
                     :fixes="card.future_fixed[0].amount ? card.future_fixed[0].amount : 0"
                    />
                    
                  </div>
                </div>

              </div>

            </div>
            
          </div>
          <div class="col-lg-6 mb-lg-0">

            <div class="row">

              <!--Linha Contas a pagar-->
              <div class="col-lg-12">

                <div class="card">
                  <div class="p-3 pb-0 card-header">
                    <div class="d-flex justify-content-between">
                      <h6 class="mb-2">Contas a pagar</h6>
                    </div>
                  </div>
                  <div class="col-12">

                    <div v-if="store.state.loading_transactions" class="col-12">

                      <div class="row align-items-center row-transactions">

                          <div class="col-2 col-lg-1 ms-3 text-center">
                            <SkeletonLoad
                                :width="'45px'"
                                :height="'45px'"
                            />
                          </div>
                          <div class="col-7 col-lg-8">
                              <div class="row align-items-center">
                                  <div class="col-12 col-lg-6">
                                    <SkeletonLoad
                                        :width="'80px'"
                                        :height="'20px'"
                                        :class="'ms-2'"
                                    />
                                    <SkeletonLoad
                                        :width="'120px'"
                                        :height="'20px'"
                                        :class="'ms-2 mt-1'"
                                    />
                                  </div>
                                  <div class="col-12 col-lg-6 text-lg-center">
                                    <SkeletonLoad
                                      :width="'120px'"
                                      :height="'20px'"
                                    />
                                  </div>
                              </div>
                          </div>
                          
                          <div class="col-2 text-end">
                              
                          </div>
                          
                          

                      </div>

                    </div>

                    <div class="col-12 text-center mt-4" v-if="store.state.transactions_out.length == 0 && !store.state.loading_transactions">
                        <p>Nenhum lançamento cadastrado.</p>
                    </div>

                    <div v-if="!store.state.loading_transactions" class="contas mb-3">
                      <transactions
                        v-for="(btp, index) in store.state.transactions_out" :key="index"
                        :transaction = btp
                        @pay="payTransation"
                      />
                    </div>
                    
                  </div>
                </div>

              </div>

              <!--Linha Contas a receber-->
              <div class="col-lg-12 mt-4">

                <div class="card">
                  <div class="p-3 pb-0 card-header">
                    <div class="d-flex justify-content-between">
                      <h6 class="mb-2">Contas a receber</h6>
                    </div>
                  </div>
                  <div class="col-12">

                    <div v-if="store.state.loading_transactions" class="col-12">

                      <div class="row align-items-center row-transactions">

                          <div class="col-2 col-lg-1 ms-3 text-center">
                            <SkeletonLoad
                                :width="'45px'"
                                :height="'45px'"
                            />
                          </div>
                          <div class="col-7 col-lg-8">
                              <div class="row align-items-center">
                                  <div class="col-12 col-lg-6">
                                    <SkeletonLoad
                                        :width="'80px'"
                                        :height="'20px'"
                                        :class="'ms-2'"
                                    />
                                    <SkeletonLoad
                                        :width="'120px'"
                                        :height="'20px'"
                                        :class="'ms-2 mt-1'"
                                    />
                                  </div>
                                  <div class="col-12 col-lg-6 text-lg-center">
                                    <SkeletonLoad
                                      :width="'120px'"
                                      :height="'20px'"
                                    />
                                  </div>
                              </div>
                          </div>
                          
                          <div class="col-2 text-end">
                              
                          </div>
                          
                          

                      </div>

                    </div>

                    <div class="col-12 text-center mt-4" v-if="store.state.transactions_in.length == 0 && !store.state.loading_transactions">
                        <p>Nenhum lançamento cadastrado.</p>
                    </div>

                    <div v-if="!store.state.loading_transactions && store.state.transactions_in.length > 0" class="contas mb-3">
                      <transactions
                        v-for="(btr, index) in store.state.transactions_in" :key="index"
                        :transaction = btr
                        @pay="payTransation"
                      />
                    </div>

                  </div>
                </div>

              </div>

            </div>

          </div>
        </div>

      </div>
    </div>
  </div>

</template>

<style>
    .contas{
        max-height: 500px;
        overflow-x: hidden;
        overflow-y: auto;
    }
</style>
